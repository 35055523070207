.container-sobre {
    padding: 10rem;
    text-align: center;
}

.container-sobre p {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: .8rem;
    color: #63372c;
    margin-top: 2rem;
}

.container-sobre h3 {
    font-family: 'Quentin';
    color: #c97d60;
    font-size: 6.5rem;
    font-weight: 600;
    margin-bottom: 6rem;
}

.icones {
    width: 5rem;
    padding: 1rem;
    :hover {
        filter: invert(1);
        width: 40px;
    }
}

.row-sobre {
    padding: 0 10rem;
    gap: 10rem;
    align-items: center;
}

.row-sobre p {
    font-size: 1.6rem;
}

.img-sobre {
    width: 18rem;
}

@media (max-width: 500px) {
    .container-sobre h3 {
        font-size: 4.5rem;
    }
    .container-sobre p {
        font-size: 1.6rem;
    }
    .row-sobre {
        padding: 0rem;
        gap: 6rem;
    }
}

@import "~bootstrap/scss/bootstrap";