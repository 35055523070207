/* container geral da pagina carrinho*/
.container-carrinho {
    margin-top: 10rem !important;
    margin-bottom: 1rem;
}

.col-gap {
    gap: 15px;
}

/* card de cada item do carrinho */
.card-carrinho {
    display: flex;
    justify-content: center;
    padding: 10px 0;
    border: 0.5px solid #986d5a5e;;
    font-size: 16px;
}

/* junção das margins top dos cards */
.card-carrinho+.card-carrinho {
    border-top-width: 0;
}

/* imagem do produto */
.img-cart {
    width: 100%;
}


/* alinhamento dos itens dentro das colunas */
.col-align-items-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



/* CARD DO FECHAMENTO DE PEDIDO ----------------------------------------------------------------*/

.carrinho {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
    background-color: white;
    font-size: 16px;
    border: 0.5px solid #986d5a5e;
    font-family: 'Raleway', sans-serif;
    color: black;

    h1 {
        text-align: center;
        font-size: 2rem;
        text-transform: uppercase;
        letter-spacing: .3rem;
        color: black;
        margin-top: 2rem;
    }
}


/* margin bottom dos itens da tabela */
.table> :not(caption)>*>* {
    border-bottom: 1px solid rgba(122, 122, 122, 0.164) !important;
    padding: 10px !important;
}

/*texto da tabela */
.table {
    color: black !important;
}

/* segunda coluna da tabela (valores)*/
.col-align-items-end {
    display: flex;
    justify-content: flex-end;
}

/* botão de fechamento do pedido*/
.cart-btn {
    font-size: 1.6rem;
    width: 60%;
    border-radius: 2rem;
    padding: 1rem;
    border: none;
    background-color: #f9d1c6;
    color: #986D5A;
    margin: 0 auto 1.5rem auto;
    &:hover {
        background-color: #986D5A;
        color: #F2E5D7;
        transition: all .4s ease-in-out;
    }
}

/* botao continuar comprando */
.cart-btn-voltar{
    font-size: 1.6rem;
    width: 60%;
    border-radius: 2rem;
    padding: 0.8rem;
    background-color: transparent;
    color: #986D5A;
    border: 1px solid #986D5A;
    margin: 0.2rem auto 0 auto;
    &:hover {
        background-color: #986D5A;
        border: 1px solid #986D5A;
        color: #F2E5D7;
        transition: all .4s ease-in-out;
    }
}



.swal2-show {
    width: 90vw;

    .swal2-actions {
        display: flex;
        flex-flow: column wrap;

        button {
            width: fit-content;
            height: fit-content;
            font-size: 2rem;
            margin: 5px;
            color: #212529 !important;
            font-size: 2rem !important;
            margin-right: 1rem;
            margin-bottom: 1rem;

            border-color: #D68C45 !important;


            &:focus {
                box-shadow: 0 0 0 0.25rem rgb(253 134 13 / 30%) !important;
                transition: all .4s ease-in-out;
            }

            &:hover {
                background-color: #D68C45 !important;
                color: #FFFFFF !important;
                transition: all .4s ease-in-out !important;
            }
        }

        .swal2-cancel {
            background-color: #D68C45 !important;
            border-color: #FFFFFF !important;
        }

        .swal2-confirm {
            background-color: #FFFFFF !important;
            border-color: #D68C45 !important;
        }
    }
}


/* BANNER DO CARRINHO VAZIO*/

.carrinho-vazio {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    font-size: 1.8rem;
    border: 0.5px solid #986d5a5e;
    padding: 15px;
    font-family: 'Raleway', sans-serif;
    border-radius: 0.5rem;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: .3rem;
    
    span{
        text-align: center;
        color: black;
        margin-top: 2rem;
    }
    button{
        font-size: 1.6rem;
        width: 25%;
        border-radius: 2rem;
        margin-top: 2rem;
        padding: 1rem;
        border: none;
        background-color: #f9d1c6;
        color: #7c5747;
        &:hover{
            background-color: #986D5A;
            color: #f9d1c6;
            transition: all .4s ease-in-out;
        }
    }
}



@media (max-width: 1200px) {
    .carrinho-vazio {
        button{
            width: 50%;
        }
    }
}

@media (min-width: 800px) {
    //Estilos confirmação pagamento
    .swal2-show,
    .swal2-popup {
        width: 600px;
        height: 400px;
    }
}

@import "~bootstrap/scss/bootstrap";
