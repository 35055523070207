.carousel-xl{
  display: block;
}
.carousel-md{
  display: none;
}
.carousel-sm{
  display: none;
}

/* titulo acima do carrossel */
.cabecalho{
  font-family: 'Raleway', sans-serif;
  font-size: 1rem;
  text-transform: uppercase;
  letter-spacing: .6rem;
  color: #63372c;
  margin-top: 6rem;
}
  
/* DIV QUE CONTEM OS 3 CARDS */
.container-card{
  width: 75%;
  margin:  2rem auto 10rem auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
}
  
/* DIV DE CADA CARD */
.card-produto{
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  width: 28%;
  border-radius: 1rem;
  border: 0.5px solid #986d5a5e;
  font-family: 'Raleway', sans-serif;
  div{
    padding: 1.5rem;
    h1{
      font-size: 1.5rem;
      text-transform: uppercase;
      letter-spacing: .2rem;
      font-weight:bold;
    }
    p{
      font-size: 1.5rem;
    }
    span{
      font-weight: bold;
      font-size: 1.5rem;
    }
  }
}

/* imagem do produto */
.item-img{
  width: 85%;
  margin: auto;
}

/* link das imagens */
a{
  text-align: center;
}

/* descrição do produto */
.item-description{
  margin-bottom:5rem;
}

/* botao de compra */
.item-btn{
  position: absolute;
  font-size: 1.6rem;
  width: 50%;
  border-radius: 2rem;
  padding: 1rem;
  border: none;
  bottom: -4%;
  left: 25%;
  //background-color: #c97d60;
  //color: #faf0e6;
  background-color: #f9d1c6;
  color: #986D5A;
  &:hover{
    background-color: #986D5A;
    color: #f9d1c6;
    transition: all .4s ease-in-out;
  }
}



/* MEDIA QUERIES */
@media screen and (max-width: 1279px) {
  .carousel-xl{
    display: none;
  }
  .carousel-sm{
    display: none;
  }
  .carousel-md{
    display: block;
  }
  /* DIV QUE CONTEM OS CARDS */
  .container-card{
    width: 80%;
  }
  /* DIV DE CADA CARD */
  .card-produto{
    width: 40%;
  }
}


@media screen and (max-width: 768px) {
  .carousel-xl{
    display: none;
  }
  .carousel-md{
    display: none;
  }
  .carousel-sm{
    display: block;
    text-align: center;
  }
  /* DIV QUE CONTEM OS CARDS */
  .container-card{
    width: 85%;
    margin: auto;
    display: flex;
    justify-content: center;
    padding-bottom:5rem;
  }
  /* DIV DE CADA CARD */
  .card-produto{
    width: 80%;
  }
}

@media screen and (max-width: 480px) {
  .item-btn{
    width: 60%;
    left: 20%;
  }
}