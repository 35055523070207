#produtos-categoria {
    padding: 10rem 4rem 4rem 4rem;
    text-align: center;
    width: 85%;
    margin: auto;
}

.grid-categorias {
    font-family: 'Raleway', sans-serif;
}

.p-title {
    font-family: 'Raleway', sans-serif;
    font-size: 2rem;
    text-transform: uppercase;
    letter-spacing: .8rem;
    color: #63372c;
    margin-top: 2rem;
}

.grid-categorias h3 {
    font-family: 'Quentin';
    color: #EB6E92;   
    font-size: 6.5rem;
    font-weight: 600;
    margin-bottom: 6rem;
}

.btn-categorias {
    background-color: #FFFFFF !important;
    border-color: #D68C45 !important;
    color: #212529 !important;
    font-size: 2rem !important;
    margin-right: 1rem;
    margin-bottom: 1rem;
    &:focus {
        box-shadow: 0 0 0 0.25rem rgb(253 134 13 / 30%) !important;
        transition: all .4s ease-in-out;
    }
    &:hover {
        background-color: #D68C45 !important;
        color: #FFFFFF !important;
        transition: all .4s ease-in-out !important;
    }
}

.cards-produtos {
    text-align: center;
    margin: 3rem;
    font-size: 1.5rem !important;
}

.img-produtos{
    width: 70% !important;
}

@media (max-width: 450px) {
    .cards-produtos {
        width: 100%;
        margin-left: 0;
    }
    .grid-categorias h3 {
        font-size: 4.2rem;
    }
    .p-title {
        font-size: 1.4rem;
    }
}

.card-body {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 5rem;
}

.btn-prod {
    position: absolute;
    font-size: 1.6rem;
    width: 50%;
    border-radius: 2rem;
    padding: 1rem;
    border: none;
    bottom: -4%;
    left: 25%;
    background-color: #f9d1c6;
    color: #986D5A;

    &:hover {
        background-color: #986D5A;
        color: #f9d1c6;
        transition: all .4s ease-in-out;
    }
}

.grid-cards {
    padding: 2rem;
}


/* MEDIA QUERIE */
@media screen and (max-width: 1279px) {
    #produtos-categoria {
        width: 100%;
    }
  }
  

@import "~bootstrap/scss/bootstrap";