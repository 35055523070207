#to-top {
    width: 6em;
    position: fixed;
    z-index: 100;
    bottom: 2.5rem;
    right: 1rem;
}

@media (max-width: 690px) {
    #to-top {
        display: none;
    }
}

@import "~bootstrap/scss/bootstrap";