.ficha{
    flex-direction: row !important;
    flex-wrap: wrap;
    align-items: center;
    margin: 5% 10% 5% 10%; //revisar com padrão estabelecido para a página como um todo - aumentei margin-bottom para empurrar footer para baixo
    border: none !important;
    gap: 4rem;
}

.ficha-corpo{
    font-family: 'Nunito', sans-serif;
    display: flex;
    flex-direction: column;
    padding: 0 !important;
    gap: 3.5rem;
    margin: 0 1rem;
}

.ficha-titulo{
    font-family: 'Raleway', sans-serif;
    font-size: 2.4rem !important;
}

.card-subtitle{
    font-size: 2rem !important;
}

#avaliacao{
    margin-top: -1.5rem;
}

#avaliacao img{
    width: 2.5rem;
}

.ficha-corpo p{
    font-size: 1.6rem;
    line-height: 3.2rem;
    letter-spacing: 0.15rem;
}


.ficha button {
    background-color: #f9d1c6;
    border: none;
    color: #986D5A;
    width: 20rem;
    font-size: 1.6rem;
    border-radius: 2rem;
    padding: 1rem;
}

.ficha button:hover,
.ficha button:focus {
    background-color: #986D5A;
    color: #f9d1c6;
    transition: all .4s ease-in-out;
}


@import "~bootstrap/scss/bootstrap";