.error {
  width: 100vw;
  height: 90vh;
  margin: auto;
  padding-top: 15px;

  h1 {
    font-family: 'Quentin';
    color: #d68c45;
    font-size: 9rem;
  }

  p {
    font-family: 'Raleway', sans-serif;
    font-size: 2.5rem;
    color: black;
  }

  .container-message {
    width: 100%;
    height: fit-content;
    text-align: center;
    margin-top: 10%;
  }

  background-image: url('https://amarecuidar.s3.sa-east-1.amazonaws.com/erro/404.jpg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@import "~bootstrap/scss/bootstrap";