.carousel-banner {
    margin: auto;
    background-color: rgb(200, 206, 202);

    img {
        width: 100%;
        height: calc(100vh - 71px);
        object-fit: cover;
    }
}

/* SETAS DE ROLAGEM DO CARROSSEL */
.carousel-control-prev-icon,
.carousel-control-next-icon {
    filter: invert(1);
    width: 200px;
}

/* INDICADOR DE PAG DO CAROUSEL */
/* .carousel-indicators [data-bs-target]{
    background-color: black !important;
} */

.carousel-caption {
    top: 30% !important;

    h3 {
        font-family: 'Quentin';
        color: #d68c45;
        font-size: 9rem;
    }

    p {
        font-family: 'Raleway', sans-serif;
        font-size: 2.5rem;
        color: black;
    }
}

/* botao */
.btn-banner {
    font-family: 'Raleway', sans-serif;
    width: 170px;
    padding: 1.2rem;
    font-size: 1.6rem;
    border: 1px solid #d68c45;
    border-radius: 5px;
    background-color: #d68c45;
    color: white;
    margin-top: 7rem;
    text-transform: uppercase;
    letter-spacing: .3rem;
    font-weight: bold;
    &:hover{
        background-color: white;
        color:#d68c45;
        transition: all .4s ease-in-out;
    }
}


/* MEDIA QUERIES */
@media screen and (max-width: 1279px) {
    .carousel-caption {
        h3 {
            font-size: 7rem;
        }
    }

    .btn-banner {
        margin-top: 2rem;
    }
}


@media screen and (max-width: 768px) {
    .carousel-caption {
        h3 {
            font-size: 5rem;
        }

        p {
            font-size: 2rem;
        }

        .btn-banner {
            margin-top: 2rem;
        }
    }
}

@import "~bootstrap/scss/bootstrap";