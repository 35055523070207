/* lembrar que fixed demanda uso de margin-top de elementos seguintes, ao passo que sticky, por sua vez, não é suportado por todos os browsers 
REALIZAR ADEQUAÇÕES AO USAR FIXED=TOP*/
header {
    position: sticky;
    top: 0;
    z-index: 999;
    box-shadow: 0px 2px 2px 1px rgba(0, 0, 0, 0.2);
}

.bg-linen {
    background-color: #F2E5D7;
}

nav {
    padding: 3.5%; //verificar no mobile
    padding-top: 1.8rem !important;
    min-height: 8rem;
}

.navbar-nav {
    font-family: 'Raleway', sans-serif;
    gap: 6.5% !important;
    padding-right: 14rem; //para compensar gap no desktop
}

.nav-link {
    font-size: 1.8rem;
    color: #262322 !important;
    font-weight: 500;
    letter-spacing: .25rem;
    white-space: nowrap;
    text-align: left;

    &:hover {
        color: #d68c45 !important;
        transition: all .4s ease-in-out;
    }
}

.dropdown-menu.show {
    //margin-top: 4.75rem !important; //reduzir ou eliminar margin-top em mobile - alternativamente, eliminar o próprio dropdown no mobile
    font-size: 1.6rem;
    background-color: #262322;
    width: 20rem;
    right: 0;
    text-align: center;

    .btn-produtos {
        background-color: #f9d1c6;
        color: black;
        margin: 2.5rem auto 0.5rem auto;
        width: 90%;
        padding: 0.5rem;
        border: none;
        border-radius: 0.5rem;
        font-size: 15px;

        &:focus {
            box-shadow: 0 0 0 0.25rem rgb(253 134 13 / 30%) !important;
            transition: all .4s ease-in-out;
        }

        &:hover {
            background-color: #986D5A !important;
            color: #FFFFFF !important;
            transition: all .4s ease-in-out !important;
        }
    }
}

.dropdown-item {
    color: #F2E5D7 !important; //important está gerando conflitos com hover e focus
    line-height: 3rem;
}

.dropdown-item:hover,
.dropdown-item:focus {
    color: #262322 !important;
    background-color: #F2E5D7 !important;
}

.dropdown-divider {
    color: #EB6E92;
    height: .2rem;
}

/* carrinho */
#cart {
    // margin-right: 20px;

    //experimentando com posição fixa
    position: fixed;
    top: 24px;
    right: 14px;
}

.btn .badge {
    top: -10px !important;
    right: 15px;
    background-color: #DD5D1E !important;
}

.btn:focus {
    box-shadow: none !important;
}

/* Lista de produtos do carrinho */
#subcart {
    width: fit-content;

    //Estilos somente para a lista de produtos
    #cartList {
        overflow-y: auto;
        overflow-x: hidden;
        max-height: 400px;

        .btn-produtos {
            position: relative;
        }

        //Estilos para cada item da lista
        .cartitem {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 0 20px;
            border-bottom: 0.4px solid #f9d1c63b;
            padding: 10px 0;
            color: #ccc;

            //Descrição de cada produto da lista de produtos do carrinho
            .cartItemDetail {
                display: block;
                flex: 1;
                padding: 0 20px;
                flex-direction: column;
                color: #ccc;
            }
        }

        //Imagem da lista de produtos do carrinho
        .cartItemImg {
            border-radius: 50%;
            width: 50px;
            height: 50px;
            object-fit: cover;
        }
    }
}

#subcart>#cartList::-webkit-scrollbar {
    width: 10px;
}

#subcart>#cartList::-webkit-scrollbar-track {
    background-color: #262322;
    border: 0;
}

#subcart>#cartList::-webkit-scrollbar-thumb {
    border-radius: 100px;
    border-left: 0;
    border-right: 0;
    background-color: #000;
}

/* -------------------------------- LOGO -------------------------------- */

.navbar-brand span {
    font-family: 'Quentin', sans-serif;
    font-size: 3rem !important;
    padding-left: 2.5%;
}

.btCarrinho {
    position: relative;
    width: 500px;
}

@media (min-width: 992px) {
    .navbar-brand {
        top: 8px;
    }
}

@media (max-width: 991px) {
    .navbar-brand {
        position: fixed;
        top: 8px;
        left: 12.5%;
    }

    #cart {
        top: 16px;
    }
}

@media (max-width: 800px) {
    .navbar-brand span {
        display: none !important;
    }
}

@media (max-width: 600px) {
    .navbar-brand {
        left: 15%;
    }
}

@media (max-width: 500px) {
    .navbar-brand {
        left: 20%;
    }
}


#logo-img {
    width: 10rem; //investi em % para responsividade; aumentar % conforme diminui o viewport
    align-self: center;
    border-radius: .5rem;
}

.navbar-toggler {
    border-width: 2px !important;
    margin-bottom: 2rem;
}

.navbar-toggler-icon {
    width: 3rem !important;
    height: 3rem !important;
}

@import "~bootstrap/scss/bootstrap";