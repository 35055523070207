/* -------------------------------- FONTES -------------------------------- */

//Fontes obtidas no GoogleFonts
//RALEWAY
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//NUNITO SANS
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,wght@0,200;0,300;0,400;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,600;1,700;1,800;1,900&family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

//Fonte importada localmente
//QUENTIN
@font-face {
  font-family: 'Quentin';
  src: url('./assets/Quentin.otf') format('truetype');
}

/* ------------------------------------------------------------------------ */



body {
  // margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
}

html {
  font-size: 10px;
}


body::-webkit-scrollbar {
  width: 15px;
}

body::-webkit-scrollbar-track {
  background-color: rgba($color: #000, $alpha: .9);
  border: 0;
}

body::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border-left: 0;
  border-right: 0;
  background-color: #000;
}

$dark: #262322;