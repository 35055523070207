#categorias {
    background-color: #FFFFFF;
    padding: 10rem 4rem 4rem 4rem;
    text-align: center;
}

.row-categorias {
    gap: 4rem;
}

.item-categorias a {
    text-decoration: none;
    font-family: "Quentin";
    font-size: 4.5rem;
    color: #000000;
}

.item-categorias:hover, 
.item-categorias a:hover {
    color: #EB6E92;   
}

.item-categorias p {
    white-space: nowrap;
}

.card-title.h5 {
    font-size: 1.8rem;
}



@import "~bootstrap/scss/bootstrap";